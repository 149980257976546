.rtl .waveImage {
    transform: scalex(-1);
}

.rtl .Polyicon {
    transform: translate(50%, -50%);
}

.rtl .countDigit {
    right: unset;
    left: 8%;
}

.rtl .horizontal-line,
.rtl .indexStyle-icon-37 {
    transform: translate(50%, -50%);

}

.rtl .MuiTypography-h4::before {
    left: unset;
    right: 0%;
}

.rtl .check-icon {
    margin-right: unset;
    margin-left: 15px;

}

.rtl .overLay1,
.rtl .overLay2,
.rtl .overLay3 {
    transform: scalex(-1);
}

.rtl .ViewExpensesStyle-sectionHeading-16 .MuiTypography-h4::before {
    transform: translatex(50%);
}
.rtl .whatsApp-btn{
    left: 16px !important;
    right: unset !important;
}
.rtl .subscribe-btn {
    right: unset !important;
    left: 0;
    margin-right: unset;
    margin-left: 15px;
}
.rtl .MuiTypography-h4::before{
    transform: translatex(50%) !important;
}
.rtl .horizontal-line2::before {
    transform: translatex(0%) !important;
}

.rtl label.Mui-focused {
    right: 1.75rem;
    left: unset;
    transform-origin: top right;
}

.rt .css-1ig0vlz-MuiFormLabel-root-MuiInputLabel-root {
    right: 0;
    left: unset;
}

.rtl .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    text-align: right;
}

.rtl .MuiTextField-root .MuiInputLabel-root {
    right: 27px;
    left: unset;
}

.rtl .subscribe-content {
    padding-left: 0px !important;
    padding-right: 100px;
}

.rtl .css-e53awj-MuiStack-root>:not(style)+:not(style) {
    margin-left: unset;
    margin-right: 16px;
}

.rtl .ViewExpensesStyle-outerLink-67 .MuiTypography-body2 {
    margin: 0px 15px 0px 0px;
    text-align: right;
}

.rtl .MuiSvgIcon-root {
    transform: scaleX(-1);
}

.rtl .css-mgur8 {
    right: inherit;
    left: 16px;
}

.rtl .MuiInputLabel-root {
    right: 0;
    left: unset;
}

.rtl .MuiDrawer-paper {
    left: unset;
    right: 0;
}
.rtl .MuiDrawer-paper .MuiListItemText-root .MuiListItemText-primary{
  text-align: right;
}
.rtl .contact-img , .rtl .web-bg, .rtl .mobile-bg{
    transform: scaleX(-1);
}

.rtl .send-btn {
    float: left !important;
}
.rtl .css-cjai73-MuiButtonBase-root-MuiButton-root{
    float: unset;
}
/* .rtl .indexStyle-featureCard-39 .MuiTypography-h4{
    margin-left: unset;
    margin-right: 10px;
} */
.rtl .testimonial-bg, .rtl .contact-bg, .rtl .analytics-bg{
    transform: scaleX(-1);
}
.rtl .ViewExpensesStyle-bg1-64{
    transform: scaleX(-1);
}
.rtl .feature{
    margin-left: unset;
    margin-right: 10px;
}
.rtl .address{
    margin: 0px 15px 0px 0px !important;
    text-align: right !important;
}
.rtl .dropdown-links{
    text-align: right;
}
.rtl .slider-card{
    direction: rtl;
}
.rtl .slider-icon{
    right: unset;
    left: 45px;
    transform: scaleX(-1);
}
.rtl .slider-img{
    margin-right: unset;
    margin-left: 15px;
}
.rtl .content-section .MuiTypography-h2, .rtl .content-section .MuiTypography-body2{
    text-align: right !important;
}
.rtl .section-heading .MuiTypography-h2, .rtl .text-center .MuiTypography-h2, .rtl .text-center .MuiTypography-body2{
    text-align: center !important;
}
.rtl .social-icons .MuiTypography-inherit{
    margin-left: 16px; 
    margin-right: unset;
}
.rtl .mobile-logo{
    width: 120px !important;
    margin-left: auto;
    display: block;
}
.rtl .select-component .MuiSvgIcon-root {
    right: unset;
    left: 0;
}
.rtl .select-component .MuiSelect-select{
    padding-right: 0px !important;
}
.rtl .filter-clear-btn{
    text-align: right !important;
}
.rtl .select-component .MuiInputLabel-root {
    margin-right: -28px;
}
.rtl .angle-left{
    margin-right: auto !important;
    margin-left: unset !important;
}
.rtl .modal-card, .rtl .modal-card .modal-icon {
    flex-direction: row-reverse !important;
}
.rtl .modal-card .MuiTypography-h2, .rtl .modal-card .MuiTypography-body2{
    text-align: right !important;
}
.rtl .modal-list{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.rtl .modal-list .MuiSvgIcon-root{
    margin-left: 5px;
    margin-right: unset !important;
}
.rtl .carrier-modal-title{
    margin-left: 10px !important;
}
@media (max-width: 1510px) {
    .rtl .wave-img4 {
        bottom: 40%;
    }
}
@media (max-width: 1400px) {
    .rtl .wave-img1 {
        bottom: 7%;
    }
    .rtl .wave-img3 {
        bottom: 4%;
    }
}
@media (max-width: 1200px) {
    .rtl .subscribe-content {
        padding-left: 0;
        padding-right: 20px;
    }
    /* .rtl .countDigit h4{
        font-size: 70px; 
    } */
}

@media (min-width: 900px) {
    .rtl .border-right {
      border-right: unset;
      border-left: 2px solid rgba(0, 0, 0, 0.3);
    }
    .rtl .cloud-div{
        padding-right: unset;
        padding-left: 40px;
    }
    .rtl .content-div{
        padding-right: 40px;
        padding-left: unset;
    }
   
}
@media(max-width:900px){
    .rtl .filter-clear-btn{
        text-align: left !important;
    }
}
@media (max-width: 880px) {
  
    .rtl .wave-img1 {
        bottom: 13%;
    }
    .rtl .wave-img2 {
        bottom: 24%;
    }
    .rtl .wave-img3 {
        bottom: 12%;
    }
   
}
@media (max-width: 599px) {
    .rtl .ViewExpensesStyle-iconDiv-26 {
        margin-right: unset;
        margin-left: 8px;
    }

    .rtl .work-steps::before {
        left: unset;
        right: 42px;
    }

    .rtl .ViewExpensesStyle-stepCount-30 {
        right: unset;
        left: 0%;
    }
}

@media (max-width: 599px) {
    .rtl .ViewExpensesStyle-workStepCon-18 {
        padding-right: unset;
        padding-left: 20px;
    }
}