@import url('../src/assets/fonts/stylesheet.css');

.App {
  font-family: 'Lato', sans-serif;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.slick-dots li.slick-active button:before {
  opacity: .75;
  color: rgba(70, 67, 220, 1);
  content: "";
  height: 6px;
  width: 25px;
  background-color: rgba(70, 67, 220, 1);
  top: 7px;
  border-radius: 21px;
}

.slick-dots li button:before {
  color: rgba(70, 67, 220, 1);
  font-size: 8px;
}

.slick-dots {
  bottom: -65px;
}

@media(max-width:1400px) {
  .slick-slide img {
    display: block;
    width: 80%;
    height: 100%;
    object-fit: contain;
  }
}

@media(max-width:500px) {

  .slick-slide img {
    display: block;
    width: 85%;
    height: 85%;
    object-fit: contain;
  }
}





.privacy p,
.privacy .paragraph {
  color: #666666 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-align: justify;
}

.privacy h2 {
  color: #333333 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.015em;
}

.privacy h6 {
  color: #666666 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.015em;
  margin-bottom: 15px;
  margin-top: -14px;
}

.privacy.container {
  max-width: 1440px;
}

.privacy strong {
  font-weight: 700;
}

.privacy li {
  color: #666666 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 17px;
  font-weight: 400;
  line-height: 187%;
  letter-spacing: 0.015em;
  text-align: justify;
}

.privacy .main-section {
  margin-bottom: 35px;
}

.privacy a {
  text-decoration: none;
}

.counter-text {
  font-size: 50px !important;
  color: #4643DC !important;
  font-weight: 700 !important;
  font-family: 'Lato', sans-serif !important;
}

@media(max-width:768px) {
  .counter-text {
    font-size: 30px !important;
  }
}

.animatedPath {
  animation: movePath 5s linear infinite;
}

@keyframes movePath {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -110;
  }
}
.animatedSvg{
  top: -190px;
    left: 11px;
    position: absolute;
}

.animatedImageGrid {
  top: 288px;
  margin: 47px 0 61px !important;
  display: flex;
 
    position: absolute;
    left: 46%;

}
@media(max-width:1700px){
  .animatedImageGrid {
      left: 38%;
  
  }
}
@media(max-width:1600px){
  .animatedSvg{
    top: -220px;
    left: 11px;
    width: 625px;
  }
  .animatedImageGrid {
    top: 172px;
    margin: 47px 0 61px !important;
    display: flex;
    position: relative;
    left: 0%;
  }
}
@media(max-width:1150px){
  .animatedSvg{
    top: -226px;
        left: 20px;
        width: 500px;
  }
}
@media(max-width:900px){
  .animatedImageGrid {
    top: 85px;
  }
}
.waveImage{
  animation: stringDropShadow 3s linear infinite;
}
@keyframes stringDropShadow {
  0% {
    -webkit-filter: drop-shadow(2px 0 3px #4643dc00);
    filter: drop-shadow(2px 0 3px #4643dc00);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  45% {
    -webkit-filter: drop-shadow(2px 15px 4px #dc4343bd);
    filter: drop-shadow(2px 15px 4px #dc4343bd);
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  95% {
    -webkit-filter: drop-shadow(2px 30px 7px #4643dc00);
    filter: drop-shadow(2px 30px 8px #4643dc00);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes dropShadow {
  0% {
    -webkit-filter: drop-shadow(2px 0 3px #4643dc00);
    filter: drop-shadow(2px 0 3px #4643dc00);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  45% {
    -webkit-filter: drop-shadow(2px 30px 7px #4643dcbd);
    filter: drop-shadow(2px 30px 7px #4643dcbd);
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  95% {
    -webkit-filter: drop-shadow(2px 60px 14px #4643dc00);
    filter: drop-shadow(2px 60px 14px #4643dc00);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.loader{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#blog_id_5 .blog-points .MuiTypography-h4{
  font-size: 16px !important; 
}
.dropdown-links{
  border-bottom: unset !important;
}
@media (min-width: 600px) {
  .css-13raia8-MuiButtonBase-root-MuiMenuItem-root {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
  }
}

 text{
  stroke: #1f2b72;
  stroke-width: 2px;
  animation: textAnimate1 5s infinite alternate;
  letter-spacing: 2px;
}
.red-text{
  stroke:  #c33a3a;
  animation: textAnimate2 5s infinite alternate;
}
.text-svg{
  overflow: visible;
  height: 1px;
  width: 250px;
}
.text-svg2{
  overflow: visible;
  height: 1px;
  width: 225px;
}
@keyframes textAnimate1 {
  0% {
    stroke-dasharray: 0 50%;
    stroke-dashoffset: 20%;
    fill: #3F51B5;
}
100% {
    stroke-dasharray: 46% 0;
    stroke-dashoffstet: -20%;
    fill: #3F51B5;
}
  
}
@keyframes textAnimate2 {
  0% {
    stroke-dasharray: 0 50%;
    stroke-dashoffset: 20%;
    fill: rgb(240, 23, 23);
}
100% {
    stroke-dasharray: 46% 0;
    stroke-dashoffstet: -20%;
    fill: rgb(240, 23, 23);
}
  
}
@media (max-width: 1400px) {
  .text-svg {
    width: 170px;
}
.text-svg2 {
  width: 155px;
}
}
@media (max-width: 600px) {
  .text-svg {
    width: 125px;
}
.text-svg2 {
  width: 115px;
}
text{
  stroke-width: 1px;
}
}
.call-us-div{
  position: fixed;
  right: 1px;
  top: 23%;
  transform: rotate(180deg) translateY(-45%);
}
.call-us-div span{
  background-color: black;
  color: white;
  padding: 11px;
  border-radius: 5px;
  font-size: 14px;
  writing-mode: vertical-lr;
  height: max-content;
}